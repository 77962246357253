import React, { useState } from "react"
// import { Link } from "gatsby"
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Input,
  InputGroupAddon,
  Alert,
  Button,
  Table,
  Badge
} from "reactstrap"
// import OverlayLoader from "react-overlay-loading/lib/OverlayLoader"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = props => {
  const [keyword, setKeyword] = useState(null)
  const [isActiveLoader, setIsActiveLoader] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [items, setItems] = useState()

  const postData = async (url = "", data = {}) => {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "GET",
    })
    return response.json() // parses JSON response into native JavaScript objects
  }

  const handleSubmit = async event => {
    event.preventDefault()

    if (keyword !== null && keyword.trim().length >= 2) {
      setIsActiveLoader(true)

      setItems([])

      // postData('https://s3.casedesign2u.com/?keyword=aa')
      // postData("http://api.casedesign2u.com/api/track/" + keyword).then(
      postData("https://api.casedesign2u.com/api/track/" + keyword).then(
        data => {
          if (data.length > 0) {
            setItems(data)

            setIsActiveLoader(false)

            setIsLoaded(true)
          } else {
            setIsActiveLoader(false)

            setIsLoaded(true)
          }
        }
      )
    }
  }
  return (
    <Layout>
      <SEO title="Track" />
      <Container>
        <Row>
          <Col>
            <Alert color="primary">กรุณาใส่ ชื่อ เช่น ภัคพงษ์</Alert>

            <Form onSubmit={handleSubmit}>
              <InputGroup>
                <Input onChange={e => setKeyword(e.target.value)} />
                <InputGroupAddon addonType="append">
                  <Button color="primary">ตรวจสอบข้อมูล</Button>
                </InputGroupAddon>
              </InputGroup>
            </Form>
          </Col>
        </Row>

        <Row>
          <Col>
            {/* <OverlayLoader
              color={"#067bc4"} // default is white
              loader="BounceLoader" // check below for more loaders
              text=""
              active={isActiveLoader}
              backgroundColor={"#eaecef"} // default is black
              opacity=".4" // default is .9
            > */}
              {isActiveLoader ? (
                <div className="text-center">กำลังโหลดข้อมูล...</div>
              ) : null}
            {/* </OverlayLoader> */}

            {isLoaded ? (
              <Table striped responsive>
                <thead>
                  <tr>
                    <th>ชื่อ</th>
                    <th>นามสกุล</th>
                    <th>รหัสพัสดุ</th>
                    <th>ประเภทการจัดส่ง</th>
                    <th>สถานะ</th>
                    <th>ตัวเลือก</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map(item => (
                    <tr key={item.order_id}>
                      <td>{item.firstname}</td>
                      <td>{item.lastname}</td>
                      <td>{item.tracking}</td>
                      <td>
                        {item.shipping_type === 1 ? (<Badge color="primary">{item.shipping_type_name}</Badge>) : null}
                        {item.shipping_type === 2 ? (<Badge color="danger">{item.shipping_type_name}</Badge>) : null}
                        {item.shipping_type === 3 ? (<Badge color="warning">{item.shipping_type_name}</Badge>) : null}
                      </td>
                      <td>
                        {item.order_status === 1 ? (<Badge color="warning">{item.order_status_name}</Badge>) : null}
                        {item.order_status === 2 ? (<Badge color="info">{item.order_status_name}</Badge>) : null}
                        {item.order_status === 3 ? (<Badge color="primary">{item.order_status_name}</Badge>) : null}
                        {item.order_status === 4 ? (<Badge color="success">{item.order_status_name}</Badge>) : null}
                      </td>
                      <td>
                        <a 
                          href={`https://track.aftership.com/${item.tracking}`}
                          className="btn btn-secondary"
                          target="_blank"
                          rel="noopener noreferrer"
                          >
                          ติดตามพัสดุ
                        </a>
                      </td>
                    </tr>
                  ))}

                  {items.length === 0 ? (
                    <tr className="danger">
                      <td className="text-center" colSpan={7}>
                        ไม่พบข้อมูล !
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </Table>
            ) : null}
          </Col>
        </Row>
      </Container>

      {/* <Link to="/page-2/">Go to page 2</Link> */}
    </Layout>
  )
}

export default IndexPage
